import React from 'react';
import 'react-multi-carousel/lib/styles.css';

import challengeOne from '../../assets/images/jobbox/challenge-1.png';
import challengeTwo from '../../assets/images/jobbox/challenge-2.png';
import jobboxOneA from '../../assets/images/jobbox/jobbox1-a.png';
import jobboxOneB from '../../assets/images/jobbox/jobbox1-b.png';
import jobboxOneC from '../../assets/images/jobbox/jobbox1-c.png';
import jobboxTwoA from '../../assets/images/jobbox/jobbox2-a.png';
import jobboxTwoB from '../../assets/images/jobbox/jobbox2-b.png';
import jobboxTwoC from '../../assets/images/jobbox/jobbox2-c.png';
import review from '../../assets/images/jobbox/review.png';
import jobBox from '../../assets/images/jobbox/jobbox-banner.png';
import tinLogo from '../../assets/images/gamerize/tinLogo.png';

import bookmark from '../../assets/images/jobbox/jobbox-bookmark.png';

class Jobbox extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <section>
          <img className="jobbox-pic" src={jobBox} alt="banner" />
        </section>
        <section className="container jobbox-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12 ">
              <div className="mini-heading">Case Study - App Development</div>
              <div className="heading-one-j">JobBox</div>

              <div className="row para-mini pt-0">
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeOne} alt="" />
                  <div className="subheading">The Challenge</div>
                  <p className="desc">
                    An entrepreneur was looking for a technical partner to adapt his informal
                    business in renting out equipment to a scalable technology solution
                  </p>
                </div>
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeTwo} alt="" />
                  <div className="subheading">The Outcome</div>
                  <p className="desc">
                    Tintash understood the business nuances of the product and designed and
                    implemented a mobile application that served as an investor MVP
                  </p>
                </div>
              </div>
              <div className="subheading-big">The Project</div>
              <p className="description">
                Tintash loves to work with entrepreneurs looking to build products to live out their
                dreams. We understand budgetary constraints and hypotheses to be tested at an early
                stage and are able to chalk up project plans accordingly.
              </p>
              <br />
              <p className="description">
                The CEO of JobBox brought us a challenge that was a great fit for the Tintash way.
                He had been successful in setting up a regional basis involving renting out
                heavy-duty equipment he owned. Much of the operations was fairly informal and
                executed over phone and email. He saw the potential in using technology to build
                something much bigger and reached out to several technology vendors to pick one and
                take things forward.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-jobbox">
            <img className="subpic" src={jobboxOneA} alt="jobbox" />
            <img className="subpic" src={jobboxOneB} alt="jobbox" />
            <img className="subpic" src={jobboxOneC} alt="jobbox" />
          </div>
          <div className="img-title">JobBox - In-App Screens</div>
        </section>
        <section className="container jobbox-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="subheading-big">The Process</div>
              <p className="description">
                Tintash was selected as the development partner following several online meetings
                and presentations. The Tintash team of engineering managers and business analysts
                listened carefully to understand the use cases of the project and started mapping
                out a plan. The client had a clear budget for this stage but also clear goals that
                needed to be achieved to make further development attractive to investors. Tintash
                asked the right questions to prioritize the feature set and take the client through
                a series of UI/UX iterations. The team benchmarked the app against existing
                competitors as part of ensuring that everything made business sense.
              </p>
              <br />
              <p className="description">
                Within a few weeks, the wireframes were in place and the technology choices were
                clear: React Native was a sound choice for the cross-platform app while a robust
                backend needed to be in place to manage the inventory and transactions. As the
                product was developed over a 3-4 month period, there were weekly team calls with the
                client to stay true to the agile spirit. Priorities were re-set, options were
                debated and builds were reviewed. Each meeting was an opportunity to review the cash
                burn till now, assess how much budget was left and how much more could be achieved.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-jobbox">
            <img className="subpic" src={jobboxTwoA} alt="jobbox" />
            <img className="subpic" src={jobboxTwoB} alt="jobbox" />
            <img className="subpic" src={jobboxTwoC} alt="jobbox" />
          </div>
          <div className="img-title">JobBox - In-App Screens</div>
        </section>
        <section className="container jobbox-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                This approach ensured that a complete, consumer-friendly app was launched within
                budget and within the expected timeline. The client was in a position to start
                demo-ing it to potential investors and we are pleased to report that within a few
                months, he successfully raised funding and continued the effort.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="review" src={review} alt="jobbox" />
          </div>
        </section>
        <section className="container last-section">
          <div className="row">
            <div className="col-12">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{marginTop: '-16px'}}
              >
                <img src={bookmark} alt="bm" />
              </div>
              {/* <BookmarkIcon fontSize="large" style={bookmark} /> */}
              <div className="last-section-content">
                <p className="desc-text">
                  <span className="bold">
                    Tintash loves to work with entrepreneurs looking to build products to live out
                    their dreams.
                  </span>
                  <br />
                  We understand budgetary constraints and hypotheses to be tested at an early stage
                  and are able to chalk up project plans accordingly.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container tintash-sign">
          <div className="row">
            <div className="col-12">
              <div className="tin-text"> Created and Developed by</div>
              <div className="tin-img">
                {' '}
                <img src={tinLogo} alt="bm" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Jobbox;
